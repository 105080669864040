import axios from "axios";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { authApiUrl, authUrl, productId, selfUrl } from "../../services/api";

import { getUserInfo, isExpired, userIsAuthenticated } from "../../services/auth";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function PrivateRoute() {
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const RedirectComponent = () => {
    window.location.assign(`${authUrl}/login?continue=${selfUrl}`);
    return null;
  };
  const NotAuthorized = () => {
    return (
      <h1>Nao autorizado</h1>
    )
  }

  const Loading = () => {
    return (
      <div className="centered">
        <CircularProgress />
      </div>
    )
  }

  useEffect(() => {
    async function fetch() {
      const user = getUserInfo();
      if (user.access_token && !isExpired(user.access_token)) {
        try {
          await axios.get(`${authApiUrl}/users/${user.username}/products/${productId}/access`, { 'headers': { 'Authorization': 'Bearer ' + user.access_token } });
          setUserAuthorized(true);
        } catch (error) {
          setUserAuthorized(false);
        }
      }
      setLoaded(true);
    }
    fetch();
  }, [])


  return loaded ? (userIsAuthenticated() ? (userAuthorized ? <Outlet /> : <NotAuthorized />) : <RedirectComponent />) : <Loading />;
};
