import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import ExpandMore from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";

import "./styles.css";
import logo from "../../assets/logo-vector.png";
import Ellipsis from "../Ellipsis";
import { getUserInfo, userIsAuthenticated } from "../../services/auth";
import { logout } from "../../services/api";

export default function Header() {
  const loggedUser = getUserInfo();
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const userMenuRef = useRef(null);

  function handleLogout() {
    logout();
  }

  return (
    <header>
      <nav className="flex-row flex-axis-center flex-space-between padding-small shadow height-header">
        <div className="flex-row flex-axis-center">
          <Link
            to="/"
            className="flex-row flex-axis-center color-tertiary size-xlarge bold"
          >
            <img src={logo} height="30px" width="30px" alt="Logo" />
            &nbsp;LabLift
          </Link>
        </div>
        <div className="flex-row flex-axis-center">
          {userIsAuthenticated() && (
            <>
              <button
                className="flex-row"
                ref={userMenuRef}
                onClick={() => setOpenUserMenu(!openUserMenu)}
              >
                <ExpandMore />
              </button>
              <Menu
                anchorEl={userMenuRef.current}
                keepMounted
                open={openUserMenu}
                onClose={() => setOpenUserMenu(!openUserMenu)}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <div className="flex-column flex-axis-center padding-large">
                  <Ellipsis n_lines={1}>
                    <p>{loggedUser.name ? loggedUser.name : "Anônimo"}</p>
                  </Ellipsis>

                  <button
                    onClick={handleLogout}
                    className="margin-top-small padding-small box background-hover width-100p"
                  >
                    Logout
                  </button>
                </div>
              </Menu>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}
