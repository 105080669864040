import React, { useState } from "react";
import api from "../../services/api";
import { notifyError } from "../../utils";
import SubmitButton from "../../components/SubmitButton";
import TextField from "@material-ui/core/TextField";

export default function GenerateKey() {
  const [cpf, setCpf] = useState("");
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      cpf: cpf,
      name: name,
    };

    try {
      const response = await api.post("/keys", data);
      setKey(response.data);
    } catch (error) {
      notifyError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex-column flex-crossaxis-center min-height-100vh max-width-xxxxlarge margin-auto padding-small">
      <span className="color-tertiary bold size-xxxlarge margin-bottom-large text-center">Gerar chave</span>
      <p>Preencha os dados para gerar uma nova chave atrelada a um usuário.</p>
      <form
        className="padding-medium flex-column margin-bottom-small"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="CPF"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
        <TextField
          className="margin-top-small"
          type="name"
          label="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <SubmitButton
          standardButton={<button className="margin-top-medium button-tertiary" type="submit">Submeter</button>}
          loadingButton={<button className="flex-row flex-crossaxis-center margin-top-medium button-tertiary-disabled" value="click" disabled>Loading...</button>}
          isLoading={loading}
        />
      </form>
      {
        key && 
        <p>Chave gerada: <a className="color-tertiary" target="_blank" rel="noreferrer" href={key.link}>{key.link}</a></p>
      }

    </div>
  );
}
