import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import GenerateKey from "./pages/GenerateKey";

import PrivateRoute from "./components/PrivateRoute";

export default function RoutesModule() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Index />} />
          <Route path="/generatekey" element={<GenerateKey />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
