import React, { useState, useRef } from "react";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ClearIcon from "@material-ui/icons/Clear";

import LinearProgress from "@material-ui/core/LinearProgress";
import api from "../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/Header";
import { notifyError } from "../../utils";

export default function Index() {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [deniedFiles, setDeniedFiles] = useState([]);
  const [result, setResult] = useState();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [blockSubmit, setBlockSubmit] = useState(false);
  const [showDelayWarning, setShowDelayWarning] = useState(false);
  const delayCounterRef = useRef(0);
  const fileInputRef = useRef();
  const dropRegion = useRef();

  const extensions = ["png", "jpg", "jpeg", "PNG", "JPG", "JPEG"];
  const maxFileSize = 50000000; // bytes

  function fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  function handleFilesCheck(input) {
    const files = fileListToArray(input);
    var newAcceptedFiles = [];
    var newDeniedFiles = [];
    setResult();
    for (var i in files) {
      const size = files[i].size;
      if (size > maxFileSize) {
        files[i]["error"] = `Arquivo maior que o limite de ${maxFileSize / 1000000} MB.`;
      }
      const extension = files[i].name.split(".")[
        files[i].name.split(".").length - 1
      ];
      if (extensions.includes(extension) && (size <= maxFileSize)) {
        files[i]["src"] = URL.createObjectURL(files[i]);
        newAcceptedFiles.push(files[i]);
      } else {
        newDeniedFiles.push(files[i]);
      }
    }
    setAcceptedFiles(newAcceptedFiles);
    setDeniedFiles(newDeniedFiles);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    dropRegion.current.classList.remove("background-deactivated-rounded-imp");
    handleFilesCheck(e.dataTransfer.files);
  }

  function handleClick(e) {
    fileInputRef.current.click();
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    dropRegion.current.classList.add("background-deactivated-rounded-imp");
  };

  function handleDragLeave(e) {
    e.preventDefault();
    dropRegion.current.classList.remove("background-deactivated-rounded-imp");
  }

  function handleSelectedFiles() {
    if (fileInputRef.current.files.length) {
      handleFilesCheck(fileInputRef.current.files);
    }
  }

  async function handleSubmit() {
    setShowProgress(true);
    setBlockSubmit(true);
    var formData = new FormData();
    acceptedFiles.forEach((value) => {
      formData.append("file", value);
    });

    var delayTrigger = setInterval(
      () => {
        delayCounterRef.current = delayCounterRef.current + 1;
        if (delayCounterRef.current > 4) {
          setShowDelayWarning(true);
        }
      }, 1000);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        setProgress(
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        );
      },
    };

    try {
      const response = await api.post("predict", formData, config);
      let result = await response.data;
      result["img"] = acceptedFiles[0];
      setResult(result);
    } catch (error) {
      notifyError(error)
    } finally {
      setAcceptedFiles([]);
      setDeniedFiles([]);
      setShowProgress(false);
      setProgress(0);
      setBlockSubmit(false);
      clearInterval(delayTrigger);
      delayCounterRef.current = 0;
      setShowDelayWarning(false)
    }
  }

  function handleRemoveItem(array, setter, index) {
    var newArray = [...array];
    newArray.splice(index, 1);
    setter(newArray);
  }

  return (
    <React.Fragment>
      <Header />
      <div className="margin-auto width-100p max-width padding-small">
        <div className="content-box">
          <div
            ref={dropRegion}
            className="padding-medium cursor-pointer background-quinary-rounded background-deactivated-rounded-hover"
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e)}
            onClick={(e) => handleClick(e)}
            onDragEnter={(e) => handleDragEnter(e)}
            onDragLeave={(e) => handleDragLeave(e)}
          >
            <input
              id="disable-events"
              ref={fileInputRef}
              className="inactive"
              type="file"
              onChange={handleSelectedFiles}
            />
            <div
              id="disable-events"
              className="flex-column flex-axis-center flex-crossaxis-center"
            >
              <p>Selecione a imagem</p>
              <CloudUploadIcon
                className="icon-tertiary"
                style={{ fontSize: 40 }}
              />
            </div>
          </div>

          {(acceptedFiles.length > 0 || deniedFiles.length > 0) && (
            <div className="padding-small">
              {acceptedFiles.length > 0 && (
                <>
                  {acceptedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="flex-row flex-space-between padding-small box"
                    >
                      <div className="flex-column">
                        <img src={file.src} width={250} alt="Uploaded" />
                      </div>
                      <div className="flex-column flex-crossaxis-center">
                        {
                          !blockSubmit &&
                          <button
                            className="margin-left-small"
                            onClick={() =>
                              handleRemoveItem(
                                acceptedFiles,
                                setAcceptedFiles,
                                index
                              )
                            }
                          >
                            <ClearIcon />
                          </button>
                        }
                      </div>
                    </div>
                  ))}
                </>
              )}
              {deniedFiles.length > 0 && (
                <>
                  <p className="padding-small bold">Arquivo não aceito</p>
                  {deniedFiles.map((file, index) => (
                    <div
                      key={index}
                      className="flex-row flex-space-between padding-small box"
                    >
                      <div>{file.name} {file.error && <span className="color-error"> - {file.error}</span>}</div>
                      <button
                        className="margin-left-small"
                        onClick={() =>
                          handleRemoveItem(deniedFiles, setDeniedFiles, index)
                        }
                      >
                        <ClearIcon />
                      </button>
                    </div>
                  ))}
                </>
              )}
              {showProgress && (
                <div className="flex-row flex-axis-center padding-small box width-100p margin-bottom-small">
                  <LinearProgress
                    className="padding-sides-small width-100p"
                    variant="determinate"
                    value={progress}
                  />
                  <span className="margin-sides-xsmall min-width-small">
                    {progress}%
                  </span>
                </div>
              )}
              {
                showDelayWarning &&
                <div className="text-center button-warning-small">
                  Ainda processando, por favor, aguarde...
                </div>
              }
              {deniedFiles.length <= 0 && acceptedFiles.length > 0 && (
                <div className="flex-column">
                  {blockSubmit ? (
                    <button className="flex-row flex-crossaxis-center margin-vertical-small button-tertiary-disabled">
                      <CircularProgress />
                    </button>
                  ) : (
                    <button
                      className="margin-vertical-small button-tertiary"
                      onClick={() => handleSubmit()}
                    >
                      Ir para relatório
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          {result && (
            <div className="padding-medium">
              <p className="text-center bold">
                Relatório de estimativas antropométricas a partir de foto de
                rosto <sup><span className="button-tertiary-small">BETA</span></sup>
              </p>
              <div className="flex-wrap flex-space-between">
                <div className="flex-column margin-xsmall">
                  {result.status === "success" ? (
                    <div>                      
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Aceitabilidade da predição</span>
                        {result.acceptability
                          ? <span className="button-tertiary-small">{result.acceptability === "unacceptable" ? "Inaceitável" : (result.acceptability === "excelent" ? "Excelente" : (result.acceptability === "acceptable" ? "Aceitável" : result.acceptability))}</span>
                          : "Não disponível"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Emoção predominante</span>
                        {result.prevalent_emotion
                          ? <span className="button-tertiary-small">{result.prevalent_emotion}</span>
                          : "Não calculado"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Sexo</span>
                        {result.gender
                          ? (<span className="button-tertiary-small">{result.gender === "M" ? "Masculino" : "Feminino"}</span>)
                          : "Não calculado"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Índice de massa corporal (IMC)</span>
                        {result.bmi
                          ? <span className="button-tertiary-small">{result.bmi} kg/m²</span>
                          : "Não calculado"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Faixa IMC</span>
                        {result.bmi_class
                          ? <span className="button-tertiary-small">{result.bmi_class === "underweight" ? "Abaixo do peso" : (result.bmi_class === "normal" ? "Normal" : (result.bmi_class === "overweight" ? "Acima do peso" : (result.bmi_class === "obese" ? "Obeso" : result.bmi_class)))}</span>
                          : "Não disponível"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Etinia provável</span>
                        {result.ethnicity
                          ? <span className="button-tertiary-small">{result.ethnicity === "asian" ? "Asiático" : (result.ethnicity === "afrodescendant" ? "Afrodescendente" : (result.ethnicity === "indian" ? "Indiano" : (result.ethnicity === "white" ? "Branco" : result.ethnicity)))}</span>
                          : "Não disponível"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Idade aparente</span>
                        {result.age
                          ? <span className="button-tertiary-small">{result.age} anos</span>
                          : "Não calculado"}
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Score de lesão facial</span>
                        <span className="button-tertiary-small">{result.skin_lesion_score}</span>
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Nível de comprometimento facial</span>
                        <span className="button-tertiary-small">{result.skin_lesion_score_interpretation === "low" ? "Baixo" : (result.skin_lesion_score_interpretation === "medium-low" ? "Médio-baixo" : (result.skin_lesion_score_interpretation === "medium-high" ? "Médio-alto" : (result.skin_lesion_score_interpretation === "high" ? "Alto" : result.skin_lesion_score_interpretation)))}</span>
                      </p>
                      <p className="flex-row flex-axis-center">
                        <span className="width-input-larger">Probabilidade de utilizar óculos</span>
                        <span className="button-tertiary-small">{result.wearing_eyeglasses_probability * 100} %</span>
                      </p>

                    </div>
                  ) : (
                    <p>
                      Ocorreu um erro durante o processamento: {result.error}
                    </p>
                  )}
                </div>
                <div className="flex-column margin-xsmall">
                  {result.status === "success" ?
                    <img src={"data:image/png;base64," + result.face} width={250} alt="Calculated" /> :
                    <img className="margin-bottom-small" src={result.img.src} width={250} alt="Uploaded" />
                  }
                </div>
              </div>
              <p className="size-xsmall">Manipulação de imagens, uso de itens que cubram partes do rosto (ex: máscaras e óculos), uso de maquiagem, procedimentos estéticos, inchaço e outros podem interferir nas estimativas de medidas antropométricas baseadas em imagens de rosto. O uso das informações contidas neste relatório para discriminação ou redução do nível de serviço oferecido ao sujeito que é objeto da análise é vedado, salvo nas hipóteses da lei. A eventual revogação da autorização de compartilhamento de informações entre o sujeito que é objeto da análise e o usuário deve ser comunicada à LabLift para exclusão de todas as informações do sujeito de nossos sistemas.</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
