import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

export const isExpired = (token) => {
  let decoded_token = jwt_decode(token);
  let now = Date.now();
  return now >= decoded_token.exp * 1000;
};

export const userIsAuthenticated = () => {
  const token = getUserInfo().access_token;
  if (token && !isExpired(token)) {
    return true;
  }
  return false;
};

export function getUserInfo() {
  const cookies = new Cookies();
  const user = cookies.get("user");
  if (user) {
    return user;
  } else {
    return {};
  }
}
