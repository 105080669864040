import axios from "axios";
import { notifyUser } from "../utils";
import { getUserInfo } from "./auth";
import Cookies from "universal-cookie";

export const url = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:5000";

export const authUrl = process.env.REACT_APP_AUTH_URL
  ? process.env.REACT_APP_AUTH_URL
  : "http://localhost:3010";

export const authApiUrl = process.env.REACT_APP_API_AUTH_URL
  ? process.env.REACT_APP_API_AUTH_URL
  : "http://localhost:5010";

export const selfUrl = process.env.REACT_APP_URL
  ? process.env.REACT_APP_URL
  : "http://localhost:3000";

export const frontendDomain = process.env.REACT_APP_DOMAIN
  ? process.env.REACT_APP_DOMAIN
  : "localhost";

export const productId = "biofeatureai";

const api = axios.create({
  baseURL: `${url}/`,
});

api.interceptors.request.use(
  async (config) => {
    const access_token = getUserInfo().access_token;
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!axios.isCancel(error)) {
      if (error.response.status === 401) {
        const token = getUserInfo().access_token;
        if (token) {
          notifyUser("Autenticaçao expirada, favor autenticar novamente.");
        }
        logout();
      }
    }

    return Promise.reject(error);
  }
);

export function logout(redirect = true) {
  const cookies = new Cookies();
  cookies.remove("user", { domain: frontendDomain});
  if (redirect) {
    window.location.href = "/";
  }
}

export default api;
